import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Energyze from "../../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Regenerating night-time face serum - Energyze" lang={"EN"} />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1> Regenerating night-time face serum</H1>
                    <p>Overnight repair</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Regenerating night-time face serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    Oxygenates the skin and activates natural processes to enjoy balanced mibrobiom and powerful skin protective barrier.
                    <ul>
                        <li>Supercharge lightweight nourishing formula.</li>
                        <li>Slows aging.</li>
                        <li>Oxygenates the skin and activates natural processes to enjoy balanced microbiome and powerful skin protective barrier.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-regeneracyjne-serum-do-twarzy-na-noc-50-ml-000000000000407510.html" color="lime">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-regeneracyjne-serum-do-twarzy-na-noc-50-ml.html" color="lime">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-regeneracyjne-serum-do-twarzy-na-noc-50-ml-0107787" color="lime">Buy in Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176930-Oxygenetic_Energyze_Vitamin_C_serum_regeneracyjne_do_twarzy_na_noc_50_ml" color="lime">Buy in DOZ</ButtonColor>
                    </Container>                
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="regeneracyjne serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                            src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="regeneracyjne serum"
                        />
                    </Link>
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">
                                <p>
                                    <strong>OXYGENETIC ENERGYZE VITAMIN C Regenerating night-time face serum</strong>
                                </p>
                                <p>
                                    This serum will stimulate nightly repair processes in your skin. How?  
                                </p>
                                <p>
                                When epidermal lipids and permeability are stronger and free radicals are neutralised by antioxidants, the skin barrier is more protective and DNA threads are activated so that ageing process gets slower and the skin remains firm, supple and plump for longer. 
                                </p>
                                <p>
                                And when you wake up in the morning you can discover and enjoy the beauty of  invigorated, soft, hydrated and luminous complexion.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/regeneracyjne-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="regeneracyjne-serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to (use):</strong></p>
                                <p>Use at night before bed. Cleanse your skin before applying this serum. For external use only. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Vitamin C" desc="A powerhouse antioxidant which neutralises free radicals harmful to skin cells. Slows aging process in the body and shields against environmental stressors. Lightens pigementation and revives the skin due to enhanced collagen and elastin synthesis." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Pomegranate, fig, mulberry and ginkgo fruit extracts" desc="Extracts that effectively improve skin elasticity and reduce existing wrinkles. Rich in antioxidants, such as phenolic acids and flavonoids, they neutralise free radicals, protecting the skin from premature aging." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">

                <Effectivness>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że przy regularnym stosowaniu skóra po przebudzeniu wygląda na promienną i wypoczętą"/>
                    <EffectivnessCard color="lime" percentage="87" desc="badanych potwierdza, że produkt niweluje pierwsze oznaki starzenia "/>
                    <EffectivnessCard color="lime" percentage="97" desc="badanych potwierdza, że produkt rozjaśnia skórę i wyrównuje jej koloryt"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
